import { useState, useEffect } from 'react'
import { Button } from "primereact/button";
import { Ripple } from "primereact/ripple";
import { useCurrentUser, useUserSwitchMode } from "../Persistence/CurrentUserContext"
import { NavigationEntry } from "./NavigationEntry"
import { ENUM_ROUTES, ROUTES } from "./Routes"
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { logout } from "../feathers";
import { ErrorBoundary } from "react-error-boundary";
import { ENUM_AWESOMEICON, ENUM_ICONS } from "../Components/Icons";
import { useRequestDistributionsList } from "../Persistence/RequestDistributionsContext";
import { Badge } from 'primereact/badge';
import { useRemindersList } from '../Persistence/RemindersContext';
import { InputSwitch } from 'primereact/inputswitch';
import { useTranslation } from 'react-i18next';
import { Datenschutz } from './Datenschutz';
import { Menuinfo } from './Menuinfo';

export const Sidebar = ({ onSidebarClick, showSidebarLabel }) => {
	const { t } = useTranslation(['sidebar']);
	const currentUser = useCurrentUser();
	const userSwitchMode = useUserSwitchMode();
	const configurationsList = useConfigurationsList();
	const requestDistributionList = useRequestDistributionsList();
	const remindersList = useRemindersList();

	const [badgeRquestDuístributins, setBadgeRquestDuístributins] = useState();
	const [badgeReminders, setBadgeReminders] = useState();
	const [toggle, setToggle] = useState(false);
	const [eaamoduleFBiB, setEaamoduleFBiB] = useState(false);

	useEffect(() => {
		setEaamoduleFBiB(configurationsList.eaaModules.FBIB === '1')
	}, [configurationsList])

	useEffect(() => {
		if (requestDistributionList && requestDistributionList.length > 0) {
			setBadgeRquestDuístributins(<Badge value={'' + requestDistributionList.length} severity='danger' />);
		} else {
			setBadgeRquestDuístributins(null);
		}
	}, [requestDistributionList])

	useEffect(() => {
		const unreadReminders = !remindersList ? [] : remindersList.filter(e => e.reminded === -1)
		if (unreadReminders.length > 0) {
			setBadgeReminders(<Badge value={'' + unreadReminders.length} severity='danger' />);
		} else {
			setBadgeReminders(null);
		}
	}, [remindersList])

	const renderMenulink = () => {
		const showMenuinfo = configurationsList.eaaModules.MENUINFO.items.filter(e => e.key !== "usermanual" && e.visible === 1)
		return showMenuinfo.length > 0 ?
			<Menuinfo showSidebarLabel={showSidebarLabel} /> :
			configurationsList.eaaModules.MENULINK.map((link) => {
				return <NavigationEntry key={'link-' + link.key} icon={link.icon} label={link.title} route={ENUM_ROUTES.DASHBOARD} onSidebarClick={() => { window.open(link.url, '_blank') }} showLabel={showSidebarLabel} />
			})
	}

	function ErrorFallback({ error, resetErrorBoundary }) {
		return (
			<div role="alert">
				<p>Something went wrong:</p>
				<pre>{error.message}</pre>
				<button onClick={resetErrorBoundary}>Try again</button>
			</div>
		)
	}

	const onLogout = () => {
		logout(currentUser)
		if (window.location.pathname !== '/') {
			window.location.replace(window.location.protocol + '//' + window.location.host);
		}
	}
	const switchToFBiB = () => {
		setToggle(false)
		onSidebarClick(ENUM_ROUTES.FBIB_DASHBOARD, ENUM_ICONS.DASHBOARD, 'Dashboard')
	}
	return (
		<div className="flex flex-column h-full sidebar" >
			<div className="flex pl-2 pt-3 w-full  overflow-hidden" style={{ height: '50px' }}>
				<div className='flex align-self-center'>
					<img src="../icons/logo_eaa-tool.png" style={{ height: '30px' }} alt='EAA-Logo' />
				</div>
				<div className={showSidebarLabel && eaamoduleFBiB ? 'flex flex-grow-1 justify-content-end align-self-center' : 'hidden'}>
					<InputSwitch className='' checked={toggle} onChange={switchToFBiB} role='checkbox' />
				</div>
				<div className={showSidebarLabel && eaamoduleFBiB ? 'flex px-2 align-self-center' : 'hidden'} style={{ fontSize: '8pt' }}>
					Wechsel zu FBiB
				</div>
			</div>

			<div className="mt-3">
				<ul className="list-none p-0 m-0">
					<NavigationEntry
						icon={ENUM_ICONS.DASHBOARD}
						translation='dashboard'
						route={ENUM_ROUTES.DASHBOARD}
						onSidebarClick={onSidebarClick}
						showLabel={showSidebarLabel}
						badge={badgeReminders}
					/>
					<NavigationEntry
						icon={ENUM_ICONS.EAACASE}
						translation='eaacases'
						route={ENUM_ROUTES.EAACASES}
						onSidebarClick={onSidebarClick}
						showLabel={showSidebarLabel}
					/>
					<NavigationEntry
						icon={ENUM_ICONS.COMPANY}
						translation='companies'
						route={ENUM_ROUTES.COMPANIES}
						onSidebarClick={onSidebarClick}
						showLabel={showSidebarLabel}
					/>
					<NavigationEntry
						awesomeIcon={ENUM_AWESOMEICON.PROVIDERACTIVITIES}
						icon={ENUM_ICONS.PROVIDERACTIVITIES}
						translation='provideractivities'
						route={ENUM_ROUTES.PROVIDERACTIVITIES}
						onSidebarClick={onSidebarClick}
						showLabel={showSidebarLabel}
					/>
					{configurationsList.eaaModules.ASSOCIATEDCONTACTS !== '1' ? '' :
						<NavigationEntry
							icon={ENUM_ICONS.ASSOCIATEDCONTACTS}
							awesomeIcon={ENUM_AWESOMEICON.ASSOCIATEDCONTACTS}
							translation='associatedcontacts'
							route={ENUM_ROUTES.ASSOCIATEDCONTACTS}
							onSidebarClick={onSidebarClick}
							showLabel={showSidebarLabel}
						/>
					}
					{configurationsList.eaaModules.JOBOPENING !== '1' ? '' :
						<NavigationEntry
							icon={ENUM_ICONS.JOBPROFILE}
							translation='jobopenings'
							route={ENUM_ROUTES.JOBPROFILES}
							onSidebarClick={onSidebarClick}
							showLabel={showSidebarLabel} />
					}
					{!configurationsList.eaaModules.REQUESTDISTRIBUTION ? '' :
						<NavigationEntry
							icon={ENUM_ICONS.REQUESTDISTRIBUTION}
							translation='requestdistributions'
							route={ENUM_ROUTES.REQUESTDISTRIBUTION}
							onSidebarClick={onSidebarClick}
							showLabel={showSidebarLabel}
							badge={badgeRquestDuístributins} />
					}
					<NavigationEntry
						icon={ENUM_ICONS.STATISTICS}
						translation='statistics'
						route={ENUM_ROUTES.STATISTICS}
						onSidebarClick={onSidebarClick}
						showLabel={showSidebarLabel}
					/>
					{configurationsList.eaaModules.OVERVIEWS.SHOWOVERVIEWSICON !== '1' ? '' :
						<NavigationEntry
							awesomeIcon={ENUM_AWESOMEICON.OVERVIEWS}
							translation='overviews'
							route={ENUM_ROUTES.OVERVIEWS}
							onSidebarClick={onSidebarClick}
							showLabel={showSidebarLabel}
						/>
					}
					{currentUser.permissions !== 'ADMIN' && !userSwitchMode ? '' :
						<>
							<NavigationEntry
								awesomeIcon={ENUM_AWESOMEICON.USERADMINISTRATION}
								icon={ENUM_ICONS.USERADMINISTRATION}
								translation='useradministration'
								route={ENUM_ROUTES.USERADMINISTRATION}
								onSidebarClick={onSidebarClick}
								showLabel={showSidebarLabel}
							/>
							<NavigationEntry
								awesomeIcon={ENUM_AWESOMEICON.SETTINGS}
								icon={ENUM_ICONS.SETTINGS}
								translation='settings'
								route={ENUM_ROUTES.SETTINGS}
								onSidebarClick={onSidebarClick}
								showLabel={showSidebarLabel}
							/>
						</>}
					{renderMenulink()}
					<ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {
						// reset the state of your app
					}}>

					</ErrorBoundary>
				</ul>
			</div>


			<div className="mt-auto">
				<hr className="mx-3 border-top-1 border-none surface-border" />
				<div className="hidden flex-row mt-3 justify-content-center">
					<Button
						onClick={() => { onSidebarClick(ENUM_ROUTES.USERPROFILE, ENUM_ICONS.USER, 'Nutzer*innenprofil') }}
						icon={ENUM_ICONS.USER}
						aria-label={t('sidebar.userprofile.aria-label')}
						className="p-button-rounded p-button-outlined color-lessblack" />
				</div>
				<div className="p-ripple my-3 gap-2 flex flex-row align-items-center cursor-pointer p-3 lg:justify-content-start text-600 border-left-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
					tabIndex={0}
					aria-label={t('sidebar.logout.aria-label')}
					onClick={onLogout}>
					<i className="flex fa fa-sign-out mr-2 lg:mr-0 text-base lg:text-2xl text-pink-500"></i>
					<span className={(showSidebarLabel ? 'flex inline' : 'hidden') + " font-medium"}>{t('sidebar.logout.label')}</span>
					<Ripple />
				</div>
			</div>

		</div>
	)

}
