import { format } from "date-fns";
import { Column } from 'primereact/column';
import { ContextMenu } from "primereact/contextmenu";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";
import { Badge } from 'primereact/badge';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useBreadcrumbsAdd } from "../Persistence/Breadcrumbs";
import { useConfigurationsList, useFBiBMode, useFormatDate } from "../Persistence/ConfigurationsContext";
import { useDataViewFilter, useDataViewFilterUpdate, useDataViewMounted, useDataViewPagination, useDataViewPaginationUpdate, useDataViewSort, useDataViewSortUpdate } from "../Persistence/DataViewFilterContext";
import { useRegionsList } from "../Persistence/RegionsContext";
import { logDefault } from "../Utils/logger";
import { DateFilterElement } from "./FilterTemplates/DateFilterElement";
import { FilterTemplateMultiSelect } from "./FilterTemplates/FilterTemplateMultiSelect";
import { MultiSelectFilterElement } from "./FilterTemplates/MultiSelectFilterElement";
import { ENUM_AWESOMEICON_SIZE, ENUM_ICONS, Icon } from "./Icons";
import { OpenEye } from "./OpenEye";
import { ResponsibleMultiSelect } from "./FilterTemplates/ResponsibleMultiSelect";
import { Button } from "primereact/button";
import { useCurrentUser } from "../Persistence/CurrentUserContext";
import { ConfirmDialog } from "./ConfirmDialog";
import { generateReport, getDataPagination } from "../feathers";
import { InputText } from "primereact/inputtext";
import { useStrgPressed } from "../Persistence/StrgPressed";
import { ENUM_ROUTES } from "../Navigation/Routes";
import { UnloadComponent } from "./UnloadComponent";

const CLASSNAME = 'TemplateDataViewPagination'
const delayMilliseconds = 1000
export const TemplateDataViewPagination = ({ dataviewConfig, columns, showOpenLinkColumn, bodyOpenLink, ROUTE, routeIdField, classNameDataTable, scrollHeight, dataTableWidth, buttons, onDoubleClick, refreshTime, menuModelRoute }) => {
	const formatDate = useFormatDate();
	const fbibMode = useFBiBMode();
	const ctlPressed = useStrgPressed();
	const addBreadcrumb = useBreadcrumbsAdd();
	const configurationsList = useConfigurationsList();
	const dataViewFilter = useDataViewFilter();
	const setDataViewFilter = useDataViewFilterUpdate();
	const dataViewSort = useDataViewSort();
	const setDataViewSort = useDataViewSortUpdate();
	const dataViewPagination = useDataViewPagination();
	const setDataViewPagination = useDataViewPaginationUpdate();
	const regionsList = useRegionsList();
	const currentUser = useCurrentUser();
	const [dataviewList, setDataviewList] = useState([]);
	const [totalRecords, setTotalRecords] = useState(0)
	const dataViewMounted = useDataViewMounted();
	const [paginationFirst, setPaginationFirst] = useState(dataViewPagination[dataviewConfig.key].paginationFirst);
	const [paginationRows, setPaginationRows] = useState(dataViewPagination[dataviewConfig.key].paginationRows || 15);
	const [currentPage, setCurrentPage] = useState(dataViewPagination[dataviewConfig.key].currentPage);
	const [selectedRow, setSelectedRow] = useState();
	const [multiSortMeta, setMultiSortMeta] = useState(dataViewSort[dataviewConfig.key]);
	const [filters, setFilters] = useState(dataViewFilter[dataviewConfig.key]);
	const [filterValues, setFilterValues] = useState();
	const [mounted, setMounted] = useState(false);
	const [initPagination, setInitPagination] = useState(false);
	const [renderFunctions, setRenderFunctions] = useState();
	const [listFilterElements, setListFilterElements] = useState();
	const [showExportButton, setShowExportButton] = useState(false);
	const [filtersActive, setFiltersActive] = useState(false);
	const [displayExportConfirmDialog, setDisplayExportConfirmDialog] = useState(false);
	const [delay, setDelay] = useState(false);
	const cm = useRef(null);
	const dt = useRef(null);

	useEffect(() => {
		if (mounted) {
			refreshData();
		}

	}, [mounted, refreshTime])

	useEffect(() => {
		if (!mounted) {

			setRenderFunctions({ 'renderDate': renderDate, 'renderList': renderList, 'renderListIcon': renderListIcon, 'renderTag': renderTag })
			setListFilterElements({ 'listFilter': listFilterElement, 'dateFilter': dateFilterElement, 'singleTagFilter': singleTagFilterElement, 'multiTagFilterElement': multiTagFilterElement, 'responsibleFilterElement': responsibleFilterElement, 'multiSelectFilterElement': multiSelectFilterElement })
			setShowExportButton(dataviewConfig.exportRoles && dataviewConfig.exportRoles.includes(currentUser.permissions))
			setMounted(true);
		}
	}, [configurationsList, regionsList])

	useEffect(() => {
		if (dataViewMounted) {
			setFilters(dataViewFilter[dataviewConfig.key])
		}
	}, [dataViewMounted])

	useEffect(() => {
		if (dataViewMounted) {
			let filterActive = false
			for (let key of Object.getOwnPropertyNames(filters || {})) {
				if (filters[key].value !== null && ('' + filters[key].value !== '')) {
					filterActive = true
				}
			}
			setFiltersActive(filterActive)
			setDataViewFilter(dataviewConfig.key, filters).then(() => {
				refreshData();
			});
			setPaginationFirst(0);
			setCurrentPage(1);
			setDataViewPagination(dataviewConfig.key, { paginationFirst: 0, paginationRows, currentPage: 0 })
		}

	}, [filters])

	const saveAsExcelFile = (buffer, fileName) => {
		import('file-saver').then(module => {
			if (module && module.default) {
				let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
				let EXCEL_EXTENSION = '.xlsx';
				const data = new Blob([buffer], {
					type: EXCEL_TYPE
				});

				module.default.saveAs(data, format(new Date().getTime(), 'yyyy-MM-dd_HH-mm-ss') + '_EAA-DOQ_' + fileName + EXCEL_EXTENSION);
			}
		});
	}

	const generateExport = async () => {
		const excelBuffer = await generateReport(dataviewConfig)
		saveAsExcelFile(excelBuffer, dataviewConfig.exportFilename);
	}

	const refreshData = async () => {
		if (mounted) {
			await getDataPagination(dataviewConfig).then((result) => {
				setTotalRecords(result.total);
				setDataviewList(result.data);
			})
		}
	}

	const renderOpenLink = (rowData) => {
		return <OpenEye ROUTE={ROUTE} id={routeIdField ? rowData[routeIdField] : rowData.id} />
	}

	const invokeChangeFilter = useCallback(() => {
		if (new Date().getTime() > (delay + delayMilliseconds)) {
			logDefault(CLASSNAME, 'info', 'REFRESH DATA ' + (new Date().getTime() > (delay + delayMilliseconds)), delay + 1000)
			refreshData();
		}
	}, [delay, mounted])

	const onChangeFilter = async (e) => {
		logDefault(CLASSNAME, 'info', 'onChangeFilter ', e.filters)
		const filters = e.filters;
		setFilters(filters);
		setPaginationFirst(0);
		setCurrentPage(1);
		setDataViewPagination(dataviewConfig.key, { paginationFirst: 0, paginationRows, currentPage: 0 })
		await setDataViewFilter(dataviewConfig.key, filters)
		const timestamp = new Date().getTime()
		setDelay(timestamp)
		setTimeout(() => {
			invokeChangeFilter()
		}, delayMilliseconds);

	}

	const onChangeSort = async (e) => {
		try {
			setMultiSortMeta(e.multiSortMeta)
			await setDataViewSort(dataviewConfig.key, e.multiSortMeta)
			refreshData();
		} catch (error) {
			console.error('Filtererror:', error)
		}
	}
	const onChangePaginator = async (event) => {
		if (!initPagination) {
			//event.first = 0
		}
		setInitPagination(true);
		setPaginationFirst(event.first);
		setPaginationRows(event.rows);
		setCurrentPage(event.page);
		logDefault(CLASSNAME, 'info', dataviewConfig.key + ' onChangePaginator: paginationFirst:' + paginationFirst, event)
		await setDataViewPagination(dataviewConfig.key, { paginationFirst: event.first, paginationRows: event.rows, currentPage: event.page })
		refreshData();
	}

	const removeAllFilter = async () => {
		const emptyFilter = {}
		for (let key of Object.getOwnPropertyNames(filters)) {
			const filter = filters[key]
			filter.value = null
			emptyFilter[key] = filter
		}
		await setDataViewFilter(dataviewConfig.key, emptyFilter);
		setFilters(emptyFilter);
		refreshData();
	}

	const menuModel = [
		{
			label: 'Öffnen', icon: ENUM_ICONS.EYE, command: () => {
				let route = ROUTE + '/' + selectedRow.id
				if (menuModelRoute) {
					route = menuModelRoute(selectedRow)
				}
				addBreadcrumb(``, route)
			}
		},
		{
			label: 'In neuem Tab öffnen', icon: ENUM_ICONS.EYE, command: () => {
				let route = ROUTE + '/' + selectedRow.id
				if (menuModelRoute) {
					route = menuModelRoute(selectedRow)
				}
				window.open(window.location.origin + '/' + route, '_blank')
			}
		},
	];

	const setFilterValue = (field, value) => {
		const _filterValues = { ...filterValues };
		_filterValues[field] = value;
		setFilterValues(_filterValues);
	}



	const textFilterElement = (options) => {
		if (!options.value) {
			options.value = ''
		}
		return <InputText
			className={options.value ? 'eaa-filter-active' : ''}
			value={options.value}
			placeholder={options.filterModel && options.filterModel.filterPlaceholder ? options.filterModel.filterPlaceholder : null}
			onChange={(e) => { options.filterApplyCallback(e.target.value) }} />
	}

	const listFilterElement = useCallback((options) => {
		const listOptions = options.filterModel ? configurationsList[options.filterModel.filterListName] : [];
		const className = options.filterModel && options.filterModel.filterElementClassName ? options.filterModel.filterElementClassName : '';
		const placeholder = options.filterModel && options.filterModel.filterPlaceholder ? options.filterModel.filterPlaceholder : null;
		logDefault(CLASSNAME, 'info', dataviewConfig.key + ' listFilterElement placeholdeer' + placeholder + ' options:', options)
		return !listOptions ? null : <Dropdown
			value={options.value}
			className={className + (options.value ? 'eaa-filter-active' : '')}
			panelClassName={fbibMode ? 'fbib p-2' : 'p-2'}
			optionLabel="datatable"
			optionValue="alias"
			options={listOptions}
			onChange={(e) => { options.filterApplyCallback(e.value) }}
			itemTemplate={listItemTemplate}
			valueTemplate={(option) => listValueTemplate(option, placeholder)}
			placeholder={placeholder} />;

	}, [configurationsList])

	const renderTagFilter = (option) => { return <Tag key={'tag-' + option.id} className={"mr-2 text-white px-3 color-tag-" + option.color} rounded value={option.datatable} /> }
	const singleTagFilterElement = (options) => {
		const listOptions = options.filterModel ? configurationsList[options.filterModel.filterListName] : [];
		const className = options.filterModel && options.filterModel.filterElementClassName ? options.filterModel.filterElementClassName : '';
		const placeholder = options.filterModel && options.filterModel.filterPlaceholder ? options.filterModel.filterPlaceholder : null;
		logDefault(CLASSNAME, 'info', dataviewConfig.key + ' singleTagFilterElement listOptions' + className, listOptions)
		return <Dropdown
			value={options.value}
			className={className}
			panelClassName={fbibMode ? 'fbib' : ''}
			optionLabel="datatable"
			optionValue="alias"
			options={listOptions}
			onChange={(e) => options.filterApplyCallback(e.value)}
			itemTemplate={renderTagFilter}
			placeholder={placeholder} />;
	}
	const multiTagFilterElement = (options) => {
		return <FilterTemplateMultiSelect options={options} key={'FilterTemplateMultiSelect_' + options.field} />
	}
	const multiSelectFilterElement = (options) => {
		return <MultiSelectFilterElement options={options} key={'MultiSelectFilter_' + options.field} />
	}
	const responsibleFilterElement = (options) => {
		return <ResponsibleMultiSelect value={options.value} onChange={(value) => { options.filterApplyCallback(value) }} />
	}
	const dateFilterElement = (options) => {
		const filter = dataviewConfig.filters[options.field]
		return <DateFilterElement options={options} maxDate={filters[options.field].maxDate ? new Date() : undefined} minDate={filters[options.field].minDate ? new Date() : undefined} />
	}

	const renderTag = useCallback((rowData, column) => {
		const filterAlias = column.column.props.filterField
		const values = Array.isArray(rowData[column.field]) ? rowData[column.field] : [rowData[column.field]]
		const list = configurationsList[dataviewConfig.filters[filterAlias].filterListName] ? configurationsList[dataviewConfig.filters[filterAlias].filterListName] : [];
		return values.map(value => {
			const alias = value.constructor === Object ? value.alias : value;
			const option = list.find(entry => entry.alias === alias);
			const style = option && option.backcolor ? { color: '#' + option.color, backgroundColor: '#' + option.backcolor, borderColor: '#' + option.color, borderStyle: 'solid', borderWidth: option.backcolor.toLowerCase() === 'ffffff' ? '1px' : '0px' } : {};
			const className = 'px-3 mx-1' + (option && option.backcolor ? '' : (option ? 'text-white color-tag-' + option.color : ''));
			return <Tag key={'tag_' + rowData.id + '_' + alias} style={style} className={className} rounded value={option ? (option.datatable || option.alias) : '---'} />
		})
	}, [dataviewConfig])

	const renderDate = (rowData, column) => { return (rowData[column.field] ? formatDate(rowData[column.field]) : '---') }
	const renderListIcon = (rowData, column) => {
		const filterAlias = column.column.props.filterField
		const list = configurationsList[filters[filterAlias].filterListName] ? configurationsList[filters[filterAlias].filterListName] : [];
		const entry = list.find(e => rowData[column.field] === e.alias);
		const badge = entry.functionBadge && entry.functionBadge(rowData) ? <Badge severity='danger' className='fa-badge' /> : '';
		return entry ? (entry.icon ? <i className={'flex w-full justify-content-center ' + entry.icon} /> : <Icon ENUM_AWESOMEICON={entry.awesomeIcon} size={ENUM_AWESOMEICON_SIZE.X1} className={'flex w-full justify-content-center '} badge={badge} />) : rowData[column.field];
	}
	const renderList = useCallback((rowData, column) => {
		const filterAlias = column.column.props.filterField
		const list = configurationsList[dataviewConfig.filters[filterAlias].filterListName] ? configurationsList[dataviewConfig.filters[filterAlias].filterListName] : [];
		const entry = list.find(e => rowData[column.field] === e.alias)
		return entry ? entry.datatable : rowData[column.field];
	}, [dataviewConfig])

	const getDoubleClickCommand = (e) => {
		if (onDoubleClick) {
			onDoubleClick(e.data)
		} else if (!showOpenLinkColumn) {
			console.log('KEINE AKTION')
		} else {
			const id = routeIdField ? e.data[routeIdField] : e.data.id;
			if (id) {
				if (ctlPressed) {
					const url = window.location.href.replace(ENUM_ROUTES.OVERVIEWS, ROUTE)
					window.open(url + '/' + id, '_blank', { rel: "noopener noreferrer" });
				} else {
					addBreadcrumb(``, `${ROUTE}/` + id)
				}
			}
		}
	}

	const paginatorTemplate = {
		layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
		'RowsPerPageDropdown': (options) => {
			const dropdownOptions = [
				{ label: 15, value: 15 },
				{ label: 30, value: 30 },
				{ label: 50, value: 50 },
				{ label: 100, value: 100 }
			];

			return (
				<>
					<span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Einträge pro Seite: </span>
					<Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
				</>
			);
		},
		'CurrentPageReport': (options) => {
			return (<div style={{ marginLeft: '20px', color: 'var(--text-color)', userSelect: 'none', width: '200px', textAlign: 'center' }}>
				<span >
					{options.first} - {options.last} von {totalRecords}
				</span>
				<span style={{ display: 'none', marginLeft: '20px', marginRight: '20px', color: 'var(--text-color)', userSelect: 'none', width: '30px', textAlign: 'center' }}>
					Seite {currentPage + 1}
				</span>
			</div>
			)
		}
	};

	const unmounteFunction = () => {
		logDefault(CLASSNAME, 'info', 'Unload TemplateDataView ' + dataviewConfig.alias + ' mounted:' + mounted);
		setMounted(false)
	}

	return (
		<div className={'flex flex-column overflow-x-auto  justify-content-center select-none ' + (classNameDataTable || 'eaa-dataView')} >
			<UnloadComponent unmountFunction={unmounteFunction} />
			<div className={"flex justify-content-end flex-wrap card-container gap-2 my-2"}>
				<div className={"flex flex-grow-1 mb-2"}>
					<div className={"flex align-self-center justify-content-left"}>
						<Button disabled={!filtersActive} icon={'pi pi-filter-slash'} label="Alle Filter zurücksetzen" onClick={removeAllFilter} />
					</div>
				</div>
				<div className={!showExportButton ? 'hidden' : "flex align-self-center justify-content-left mb-2"}>
					<Button icon={ENUM_ICONS.EXPORT} label="Als Excel-File exportieren" onClick={() => setDisplayExportConfirmDialog(true)} />
				</div>
				{(buttons || []).map((b) => <div className="flex align-self-center justify-content-left mb-2" key={'div-' + b.key}>{b}</div>)}
			</div>
			<div className={"flex card flex md:inline-flex"} >
				<ContextMenu model={menuModel} ref={cm} onHide={() => setSelectedRow(null)} />
				<ConfirmDialog
					message={`<span>Bitte beachten Sie beim Herunterladen von Daten folgende Punkte:
					<ol>
						<li><b>Exportzeitpunkt und -details:</b> Der Zeitpunkt des Daten-Exports, die Datenmenge und die angewendeten Filter werden zusammen mit Ihrem Nutzer*innen-Zugang dauerhaft und manipulationssicher gespeichert. Im Falle einer unberechtigten Datennutzung oder eines Datenverlustes können diese Informationen für disziplinarische Maßnahmen genutzt werden.</li>
						<li><b>Verantwortungsbewusster Umgang:</b> Gehen Sie verantwortungsbewusst mit den exportierten Daten um. Löschen Sie die exportierten Daten direkt nach ihrer Verarbeitung, um unbefugten Zugriff und unbefugte Weiternutzung zu verhindern.</li>
						<li><b>Abstimmung vor Nutzung:</b> Koordinieren Sie die Nutzung der exportierten Daten im Voraus mit den EAA Koordinator*innen und dokumentieren Sie dies nachvollziehbar. Stellen Sie sicher, dass die Nutzung den geltenden Datenschutzbestimmungen entspricht.</li>
						<li><b>Lokale Datennutzung:</b> Verwenden Sie die exportierten Daten ausschließlich auf Ihrem lokalen Gerät. Löschen Sie die Daten nach der Nutzung.</li>
						<li><b>Keine Weitergabe:</b> Teilen Sie die exportierten Daten niemals mit anderen Benutzern oder Dritten, es sei denn, dies wurde von den EAA Koordinator*innen ausdrücklich genehmigt.</li>
					</ol>Durch die Beachtung dieser Richtlinien tragen Sie dazu bei, die Sicherheit und Integrität der exportierten Daten zu gewährleisten.
					</span>`}
					displayConfirmDialog={displayExportConfirmDialog}
					setDisplayConfirmDialog={setDisplayExportConfirmDialog}
					title='Daten exportieren'
					key='display-export-confirm-dialog'
					style={{ width: '800px' }}
					className={' '}
					handleOnClick={generateExport} />

				{!renderFunctions ? '' :
					<DataTable emptyMessage={'keine Einträge gefunden'}
						scrollHeight={scrollHeight || 'calc(100vh - 220px)'}
						scrollable scrollDirection="both"
						className='w-full template-dataview'
						rowHover
						showGridlines
						size="small"
						lazy={true}
						responsiveLayout="scroll"
						dataKey="id"
						stripedRows
						value={dataviewList}
						selection={selectedRow}
						removableSort
						onSort={onChangeSort}
						sortMode="multiple"
						multiSortMeta={multiSortMeta}
						filters={filters}
						filterDisplay='row'
						onFilter={(e) => setFilters(e.filters)}
						paginator
						paginatorTemplate={paginatorTemplate}
						first={paginationFirst}
						rows={paginationRows}
						totalRecords={totalRecords}
						onPage={(e) => onChangePaginator(e)}
						contextMenuSelection={selectedRow}
						ref={dt}
						onContextMenuSelectionChange={e => setSelectedRow(e.value)}
						onRowDoubleClick={getDoubleClickCommand}
						onContextMenu={e => { if (showOpenLinkColumn) { cm.current.show(e.originalEvent) } }}>
						{/** Tabellenspalten */}
						{columns.map((column) => {
							const columnAlias = column.filterAlias
							return (<Column
								style={column.style}
								key={dataviewConfig.key + '-' + columnAlias}
								className={column.className}
								frozen={column.frozen}
								alignFrozen={column.alignFrozen}
								rowHover
								sortable={filters[columnAlias] && filters[columnAlias].sortable ? filters[columnAlias].sortable : column.sortable}
								sortField={column.sortField ? column.sortField : null}
								filter={filters[columnAlias] && filters[columnAlias].filter ? filters[columnAlias].filter : column.filter}
								filterField={columnAlias}
								field={filters[columnAlias] && filters[columnAlias].field ? filters[columnAlias].field : column.field}
								filterPlaceholder={filters[columnAlias] ? filters[columnAlias].filterPlaceholder : null}
								filterElement={filters[columnAlias] && filters[columnAlias].filterElement ? listFilterElements[filters[columnAlias].filterElement] : textFilterElement}
								onFilterClear={() => setFilterValue(column.filter, null)}
								body={renderFunctions[column.body] ? renderFunctions[column.body] : column.body}
								header={filters[columnAlias] && filters[columnAlias].header ? filters[columnAlias].header : column.header}
								align={column.align}
								showFilterMenu={false}>
							</Column>)
						})}
						{!showOpenLinkColumn ? null : <Column
							style={{ width: '40px' }}
							frozen={true}
							alignFrozen='right'
							className='open-icon justify-content-center p-0'
							body={bodyOpenLink || renderOpenLink} />
						}
					</DataTable>}
			</div>
		</div >
	)
}

const listValueTemplate = (option, placeholder) => {
	return <div style={{ minHeight: '21px' }}>{option && option.icon ? <i className={option.icon + ' w-2rem '} /> : (option && option.awesomeIcon ? <Icon ENUM_AWESOMEICON={option.awesomeIcon} size={ENUM_AWESOMEICON_SIZE.X1} className=' pr-3 w-1rem' /> : ' ')}{option && option.onlyIcon ? ' ' : (option ? option.datatable : placeholder)}</div>
}

const listItemTemplate = (option) => {
	return <div className='flex w-full  align-items-center'>{option.icon ? <i className={option.icon + ' pr-1 w-2rem'} /> : (option.awesomeIcon ? <Icon ENUM_AWESOMEICON={option.awesomeIcon} size={ENUM_AWESOMEICON_SIZE.X1} className=' pr-3 w-1rem' /> : '')}{option.datatable}</div>
}