import { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { useOrganizationsSelectionList } from '../Persistence/OrganizationsContext';
import { DropdownChangesChecked } from '../Components/DropdownChangesChecked';
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { useUserResponsibleList } from '../Persistence/UserContext';

export const emptyRelatedUser = { id: null, version: 1, active: 1, lastname: '', firstname: '', roleType: '', relatedUserId: null, existingResponsible: false, existingRelatedUsers: [], isResponsible: false, permissions: '' }

export const RelatedUsersForm = ({ formik }) => {

	const configurationsList = useConfigurationsList();
	const userList = useUserResponsibleList();
	const organizationsSelectionList = useOrganizationsSelectionList();
	const [organization, setOrganization] = useState(null);
	const [relatedUserList, setRelatedUserList] = useState([]);

	useEffect(() => {
		if (organizationsSelectionList && organizationsSelectionList.length === 1) {
			setOrganization(organizationsSelectionList[0])
		}
	}, [organizationsSelectionList])

	useEffect(() => {
		const tempList = organization && userList ? userList.filter(entry => entry.organizationId === organization.id) : userList;
		if (!formik.values.relatedUserId) {
			const relatedUser = tempList.find(e => e.id === formik.values.id)
			if (!relatedUser) {
				formik.setFieldValue('id', null)
			}
		}
		setRelatedUserList(formik.values.relatedUserId ? tempList.filter(e => e.id === formik.values.id) : tempList);
	}, [organization, userList])

	return (
		<div className='col'>
			<div className="formgrid grid">
				<div className="field col">
					<div className='card mb-1'><div className="font-bold">Fachberater*innen</div> </div>
					<div className='card'>
						<div className={(formik.values.relatedUserId ? 'hidden' : '') + " field col mr-3 "}>
							<label htmlFor='organization' className='block'>Auswahl Träger</label>
							<Dropdown
								id="organization" showClear filter
								name="organization"
								optionLabel="name"
								value={organization}
								disabled={organizationsSelectionList.length === 1}
								options={organizationsSelectionList}
								onChange={(e) => setOrganization(e.value)}
								autoFocus
								className={'bg-white w-full'} />
						</div>
						<DropdownChangesChecked
							filter={true}
							editmode={!formik.values.relatedUserId}
							label={formik.values.relatedUserId ? 'Bei hinterlegten Fachberater*innen kann nur die Rolle geändert werden.' : 'Fachberater*in'}
							id={'id'}
							optionValue={'id'}
							itemLabel={'nameInList'}
							formik={formik}
							options={relatedUserList} />
						<DropdownChangesChecked
							editmode={true}
							label={'Rolle'}
							id={'roleType'}
							optionValue='alias'
							itemLabel={'display'}
							formik={formik}
							options={configurationsList.CaseRoleList}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}
export const validateRelatedUser = (data) => {
	let errors = {};

	if (!data.roleType) {
		errors.roleType = 'Rolle muss gesetzt sein';
	}
	if (!data.id) {
		errors.id = 'Fachberater*in muss gesetzt sein';
	}
	if (data.permissions === 'READER' && data.permissionsFBiB === 'READER' && data.roleType && data.roleType === 'R') {
		errors.user = 'Eine Verantwortliche Rolle kann nicht durch eine/n Leser*in eingenommen werden!';
	}
	if (data.existingResponsible && data.roleType === 'R' && !data.isResponsible) {
		errors.roleType = 'Verantwortliche Rolle bereits definiert';
	}
	if ((data.existingRelatedUsers || []).includes(data.id) && !data.relatedUserId) {
		errors.id = 'Fachberater*in hat bereits eine Rolle im Fall';
	}
	return errors;
}